export const FLOW_TYPES = {
  ID_CARD: 'ID_CARD',
  PASSPORT: 'PASSPORT',
};

export const USER_STEPS = {
  START_SCREEN: 'START_SCREEN',
  SELFIE_UPLOAD: 'SELFIE_UPLOAD',
  ID_UPLOAD: 'ID_UPLOAD',
  ID_UPLOAD_BACK: 'ID_UPLOAD_BACK',
  PASSPORT_UPLOAD: 'PASSPORT_UPLOAD',
  VERIFICATION_SUCCESS: 'VERIFICATION_SUCCESS',
  SELFIE_FAILED: 'SELFIE_FAILED',
  ID_FAILED: 'ID_FAILED',
  PASSPORT_FAILED: 'PASSPORT_FAILED',
  CAMERA_ACCESS: 'CAMERA_ACCESS',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  VERIFY_PHOTO: 'VERIFY_PHOTO',
};
