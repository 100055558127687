<template>
  <div class="container bg-white">
    <div
      v-if="imageSrc"
      class="error-image verify-image"
      :style="{ 'background-image': 'url(' + imageSrc + ')' }"
    />
    <p
      class="align-center"
      style="font-size: 14px"
    >
      {{ $t('verifyPhoto.title') }}
    </p>
    <p class="text-description align-center">
      {{ $t('verifyPhoto.description') }}
    </p>
    <div class="end-content">
      <a
        class="btn btn-dark"
        style="margin-bottom: 30px"
        href="javascript:"
        @click="$emit('next-step', { step: determineNextStep() })"
      >
        {{ $t('verifyPhoto.next') }}
      </a>
      <a
        class="text-gray align-center"
        style="display: block"
        href="javascript:"
        @click="$emit('next-step', { step: previousStep })"
      >
        {{ $t('verifyPhoto.tryAgain') }}
      </a>
    </div>
  </div>
</template>
<script>
import ErrorComponent from '../mixins/ErrorComponent';
import { USER_STEPS } from '../../utils/constants';

export default {
  name: 'PassportFailed',
  mixins: [ErrorComponent],
  props: {
    previousStep: {
      type: String,
      default() {
        return '';
      },
    },
  },
  emits: ['next-step'],
  methods: {
    determineNextStep() {
      switch (this.previousStep) {
        case USER_STEPS.ID_UPLOAD:
          return USER_STEPS.ID_UPLOAD_BACK;
        case USER_STEPS.ID_UPLOAD_BACK:
        case USER_STEPS.PASSPORT_UPLOAD:
          return USER_STEPS.SELFIE_UPLOAD;
        case USER_STEPS.SELFIE_UPLOAD:
          return USER_STEPS.VERIFICATION_SUCCESS;
        default:
          return USER_STEPS.START_SCREEN;
      }
    },
  },
};
</script>
