<template>
  <div
    v-if="!isDesktop"
    class="verification"
  >
    <component
      :is="currentComponent"
      v-bind="componentProps"
      @next-step="onNextStep"
      @on-capture="onPhotoCapture"
    />
  </div>
  <DesktopLink v-else />
</template>
<script type="text/javascript">
import isMobile from 'is-mobile';
import * as faceapi from 'face-api.js';
import API from '../api/api';
import Detector from '../validation/detector';
import { FLOW_TYPES, USER_STEPS } from '../utils/constants';
import determineCurrentComponent from '../utils/screens';
import DesktopLink from '../components/screens/DesktopLink.vue';

export default {
  components: { DesktopLink },
  data() {
    return {
      currentStep: null,
      previousStep: null,
      flow: FLOW_TYPES.ID_CARD,
      verificationData: null,
      loading: true,
      selectedImage: null,
      faceAttempts: 2,
    };
  },
  computed: {
    currentComponent() {
      return determineCurrentComponent(this.currentStep);
    },
    componentProps() {
      return {
        flow: this.flow,
        selectedImage: this.selectedImage,
        successUrl: this.verificationData?.success_url,
        previousStep: this.previousStep,
      };
    },
    isDesktop() {
      return !isMobile();
    },
  },
  async mounted() {
    this.code = this.$route.params.code;
    await faceapi.loadTinyFaceDetectorModel('/models');
    API.Verification.fetchByCode(this.code)
      .then((data) => {
        if (data && data.id && data.state === 'pending') {
          if (data.locale) {
            this.$root.$i18n.locale = data.locale;
          }
          API.Verification.start(data.id)
            .then(() => {
              this.verificationData = data;
              this.flow = data.verification_type === FLOW_TYPES.ID_CARD ? FLOW_TYPES.ID_CARD : FLOW_TYPES.PASSPORT;
              this.currentStep = USER_STEPS.START_SCREEN;
            });
        } else if (data.state === 'submitted' || data.state === 'finished') {
          this.verificationData = data;
          this.currentStep = USER_STEPS.VERIFICATION_SUCCESS;
        } else if (!data || (data.state !== 'pending' && data.state !== 'started')) {
          this.verificationData = null;
          this.currentStep = USER_STEPS.ERROR;
        } else {
          this.verificationData = data;
          this.flow = data.verification_type === FLOW_TYPES.ID_CARD ? FLOW_TYPES.ID_CARD : FLOW_TYPES.PASSPORT;
          this.currentStep = USER_STEPS.START_SCREEN;
        }
      })
      .catch(() => {
        console.info('Something went wrong!');
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    onNextStep(payload) {
      this.currentStep = payload.step;
      if (payload.step === USER_STEPS.VERIFICATION_SUCCESS) {
        this.onComplete();
      }
    },
    onPhotoCapture(payload) {
      const {
        type,
        image,
        shouldCheckFace,
        successStep,
        failureStep,
      } = payload;
      this.verificationData[type] = image;
      if (shouldCheckFace && FileReader && this.faceAttempts > 0) {
        this.previousStep = this.currentStep;
        this.currentStep = USER_STEPS.LOADING;
        const img = new Image();
        const fr = new FileReader();
        fr.onload = () => {
          img.src = fr.result;
          Detector.detect('face', img)
            .then((result) => {
              if (
                (result.length && this.currentStep !== USER_STEPS.SELFIE_UPLOAD)
                || (result.length === 1 && this.currentStep === USER_STEPS.SELFIE_UPLOAD)
              ) {
                this.selectedImage = image;
                this.currentStep = successStep;
              } else {
                this.selectedImage = image;
                this.currentStep = failureStep;
                this.faceAttempts -= 1;
              }
            })
            .catch(() => { this.previousStep = this.currentStep; this.currentStep = successStep; });
        };
        fr.readAsDataURL(image);
      } else {
        this.selectedImage = image;
        this.previousStep = this.currentStep;
        this.currentStep = successStep;
      }
    },
    onComplete() {
      if (this.flow === FLOW_TYPES.ID_CARD) {
        const data = {
          front: this.verificationData.front,
          back: this.verificationData.back,
          selfie: this.verificationData.selfie,
        };
        API.Verification.createIdCardVerification(this.verificationData.id, data)
          .then(() => {
            if (this.verificationData.success_url) {
              setTimeout(
                () => { window.location.href = this.verificationData.success_url; },
                2000,
              );
            }
          });
      }
      if (this.flow === FLOW_TYPES.PASSPORT) {
        const data = {
          front: this.verificationData.front,
          selfie: this.verificationData.selfie,
        };
        API.Verification.createPassportVerification(this.verificationData.id, data)
          .then(() => {
            if (this.verificationData.success_url) {
              setTimeout(
                () => { window.location.href = this.verificationData.success_url; },
                2000,
              );
            }
          });
      }
    },
  },
};
</script>
<style>
  @import "../assets/styles/style.css";
</style>
